import { useState } from "react";
import { useTranslation } from "react-i18next";
// import LanguageChanger from "./LanguageChanger";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const HamburgerMenu = () => {
    const [hamburgerClicked, setHamburgerClicked] = useState(false);
    const { t } = useTranslation();

    const handleHamburger = () => {
        if (!hamburgerClicked) {
            setHamburgerClicked(!hamburgerClicked);
        }
    };

    const handleMenu = () => {
        setHamburgerClicked(false);
    };

    return (
        <>
            <nav className="w-full h-full sticky top-0 z-50">
                <div className="relative block">
                    <input
                        onChange={handleHamburger}
                        onClick={handleMenu}
                        checked={hamburgerClicked}
                        className="checkbox w-9 h-7 top-8 left-8"
                        type="checkbox"
                    />

                    <div className="hamburger-lines w-8 h-6 top-8 left-8">
                        <span className="line line1 drop-shadow-sm"></span>
                        <span className="line line2 drop-shadow-sm"></span>
                        <span className="line line3 drop-shadow-sm"></span>
                    </div>

                    <div
                        className="relative menu-items w-full h-screen pt-24 sm:mobileLandscape:pt-14 tabletPortrait:pt-32 lg:pt-24"
                        style={{ display: hamburgerClicked ? "flex" : "none" }}
                    >
                        <div className="absolute w-12 sm:mobileLandscape:w-12 tabletPortrait:w-14 lg:w-14 h-12 sm:mobileLandscape:h-12 tabletPortrait:h-14 lg:h-14 right-8 sm:mobileLandscape:right-8 tabletPortrait:right-8 lg:right-8 top-5">
                            {/* <img src={logo} alt="Gasthaus Ochsen Brunnen" placeholder="blur" /> */}
                        </div>
                        <ul>
                            <li
                                onClick={handleMenu}
                                className="my-6 sm:mobileLandscape:my-3 tabletPortrait:my-10 lg:my-8 manrope-sp_gmbh text-2xl sm:mobileLandscape:text-xl tabletPortrait:text-3xl lg:text-3xl text-sp_blue"
                            >
                                <Link to="/">{t("hamburger-home")}</Link>
                            </li>
                            <li
                                onClick={handleMenu}
                                className="manrope-sp_gmbh text-2xl sm:mobileLandscape:text-xl tabletPortrait:text-3xl lg:text-3xl text-sp_blue"
                            >
                                <Link to="/team">{t("hamburger-team")}</Link>
                            </li>
                            <li
                                onClick={handleMenu}
                                className="my-6 sm:mobileLandscape:my-3 tabletPortrait:my-10 lg:my-8 manrope-sp_gmbh text-2xl sm:mobileLandscape:text-xl tabletPortrait:text-3xl lg:text-3xl text-sp_blue"
                            >
                                <Link to="/referenzen">{t("hamburger-referenzen")}</Link>
                            </li>
                            <li
                                onClick={handleMenu}
                                className="manrope-sp_gmbh text-2xl sm:mobileLandscape:text-xl tabletPortrait:text-3xl lg:text-3xl text-sp_blue"
                            >
                                <Link to="/dienstleistungen">{t("hamburger-dienstleistungen")}</Link>
                            </li>
                            <li
                                onClick={handleMenu}
                                className="mt-6 sm:mobileLandscape:mt-3 tabletPortrait:mt-10 lg:mt-8 manrope-sp_gmbh text-2xl sm:mobileLandscape:text-xl tabletPortrait:text-3xl lg:text-3xl text-sp_blue"
                            >
                                <Link to="/kontakt">{t("hamburger-kontakt")}</Link>
                            </li>
                            <li className="my-8 sm:mobileLandscape:my-5 tabletPortrait:my-12 lg:my-10 cursor-none">
                                <img
                                    className="w-auto h-10 tabletPortrait:h-16 lg:h-16 drop-shadow-sm"
                                    src={logo}
                                    alt="Logo"
                                />
                                {/* <LanguageChanger /> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default HamburgerMenu;
