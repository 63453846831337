import { useTranslation } from "react-i18next";

const Loading = () => {
    const { t } = useTranslation();
    return (
        <div class="w-[100%] h-[100%] bg-white flex flex-col justify-center items-center">
            <div className="w-[100vw] h-[100vh] bg-white flex flex-row items-center justify-center">
                <div
                    className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-sp_orange mr-4"
                    role="status"
                ></div>
                <p class="text-2xl titles manrope-sp_gmbh text-sp_blue">{t("loading")}</p>
            </div>
        </div>
    );
};

export default Loading;
